import React from 'react'
const smartquotes = require('smartquotes');

export default class SmartQuotes extends React.Component{
    constructor(props){
        super(props);
        this.children = this.props.children;
    }

    componentDidMount(){
        smartquotes();
    }

    componentDidUpdate(){
        smartquotes();
    }

    render(){
        return (
            <div>{this.children}</div>
        );
    }

}